import React from 'react'

const ClosingDown: React.FC = () => (
  <div className=" text-center ml-auto mr-auto">
    <div className="max-w-screen-xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:flex-col sm:align-center">
        <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
          SpeedyRoute is shutting down on February 14th
        </h1>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          All existing subscriptions will be cancelled and all payments made
          since the January 6th will be refunded within the next 14 days.
        </p>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          If you've been using SpeedyRoute as a Courier providing last-mile
          delivery services, we recommend switching to{' '}
          <a
            className="underline text-blue-500"
            href="https://getcircuit.com/teams"
          >
            Circuit for Teams
          </a>
          , our new product built by the same team, but specifically for
          Couriers doing last-mile delivery.
        </p>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Otherwise, thanks for being a customer of ours over the years and we
          hope you find a worthy replacement.
        </p>
        <p className="mt-5 text-xl  text-gray-500 sm:text-center">
          The SpeedyRoute Team
        </p>
      </div>
    </div>
  </div>
)

export default ClosingDown
